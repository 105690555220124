<template>

  <div class="container">
    <div class="home">
      <div class="heard">
        <div class="d-flex d-al-c d-ju-sb">
          <!-- <div class="title">启元-可信智能技术试验床</div> -->
          <div class="title">试验设计模型训练平台 BL-PT3</div>
          <div class="d-flex d-al-c">
            <div class="m-r-20 d-flex"><img width="30px" src="@/assets/home/tq.png" alt="" srcset="">
              <div>26℃</div>
            </div>
            <div class="m-r-20 f-14"><span class="m-r-20">{{ getNowFormatDate.date }}</span> <span>{{ getNowFormatDate.week }}</span></div>
            <div class="clock">{{ currentTime }}</div>
          </div>
          <div class="d-flex d-al-c">
            <div class="f-12 d-flex d-al-c c-pointer">
              <div class="m-r-20">风格切换</div><img width="10px" height="5px" src="@/assets/home/qh.png" alt="" srcset="">
            </div>
            <div class="login-btn c-pointer f-12 d-flex d-al-c" @click="loginOut"><img width="15px" height="18px" src="@/assets/home/tc.png" alt=""
                srcset="">
              <div class="m-l-10">退出</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="left-box">
          <div class="left-menu d-flex flex-d-c d-al-c">
            <div class="d-flex d-al-c menu-item-box m-t-20" v-for="(item, index) in menuList" :key="index"
              @click="handelTab(item.path, index)">
              <div class="menu-item-l-box d-flex d-al-c d-ju-c" :class="menuIndex == index ? 'bgl1' : 'bgl2'">
                <img width="25px" height="25px" :src="item.img" alt="" srcset="">
              </div>
              <div class="menu-item-r-box d-flex d-al-c d-ju-c f-18" :class="menuIndex == index ? 'bgr1' : 'bgr2'">{{
                item.name }}</div>
            </div>
          </div>
        </div>
        <div class="right-box d-flex flex-d-c d-g-1">
          <router-view />
        </div>
      </div>


    </div>
  </div>

</template>

<script>


export default {
  data() {
    return {
      menuList: [
        { name: '首页', img: require(`@/assets/menu/index.png`), path: "index" },
        { name: '测试', img: require(`@/assets/menu/ls.png`), path: "test" },
        { name: '试验历史', img: require(`@/assets/menu/syls.png`), path: "syhistory" },
        { name: '评估历史', img: require(`@/assets/menu/pgls.png`), path: "pghistory" },
        { name: '系统管理', img: require(`@/assets/menu/sys_setting.png`), path: "syssetting" }
      ],
      menuIndex: 0,
      currentTime: this.formatTime(new Date()),
    }
  },
  computed: {
    //获取当前日期函数
    getNowFormatDate() {
      let date = new Date(),
        obj = {
          year: date.getFullYear(), //获取完整的年份(4位)
          month: date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
          strDate: date.getDate(), // 获取当前日(1-31)
          week: '星期' + '日一二三四五六'.charAt(date.getDay()), //获取当前星期几(0 ~ 6,0代表星期天)
          // hour: date.getHours(), //获取当前小时(0 ~ 23)
          // minute: date.getMinutes(), //获取当前分钟(0 ~ 59)
          // second: date.getSeconds() //获取当前秒数(0 ~ 59)
        }

      Object.keys(obj).forEach(key => {
        if (obj[key] < 10) obj[key] = `0${obj[key]}`
        // console.log(obj[key])
      })



      return {
        date: `${obj.year}/${obj.month}/${obj.strDate}`,
        week: `${obj.week}`
      }     
      // return `${obj.year}年${obj.month}月${obj.strDate}日${obj.week} ${obj.hour}:${obj.minute}:${obj.second}`
    }
  },
  mounted() {

    let path = this.$route.path.slice(1)
    console.log('.......', path);
    // console.log(this.menuList.findIndex(item=>item.path === path));
    // 查找对应代码的下标
    var idx = this.menuList.map(item => item.path).indexOf(path);
    if (idx != -1) {
      this.menuIndex = idx
    }
    console.log(idx);
  },
  created() {
    setInterval(this.updateTime, 1000);
  },
  methods: {
    handelTab(path, index) {
      this.menuIndex = index
      this.$router.push(path)
    },
    formatTime(date) {
      return date.toLocaleTimeString();
    },
    updateTime() {
      this.currentTime = this.formatTime(new Date());
    },
    loginOut(){
      this.$router.push('login')
    }
  }
}
</script>
<style scoped>
.clock {
  font-size: 18px;
  /* font-weight: bold; */
  margin-left: 40px;
}
.container {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;

  background: #052b4b;
}

.home {
  color: #fff;
  background-image: url('../assets/home_bg.png');
  background-size: 100% 100%;
}


.heard {
  width: 100%;
  height: 96px;
  /* background: #00121B; */
  padding: 10px 40px 10px 140px;
  box-sizing: border-box;
  position: relative;
}

/* .heard::after{
  content: "";
  width: 80%;
  height: 1px;
  background: #0079cf;
  position: absolute;
  top: 60px;
  right: 20px;
}
.heard::before{
  content: "";
  width: 2px;
  height: 2px;
  background: #0096FF;
  border-radius: 100%;
  position: absolute;
  top: 59px;
  right: 20px;
} */
.title {

  font-family: PangMenZhengDao;
  font-size: 26px;
  color: #FFFFFF;
  font-style: normal;
  text-transform: none;
}

.login-btn {
  margin-left: 60px;
}

.left-box {
  width: 230px;
  height: calc(100vh - 65px - 60px);
  /* background: #052B4B; */
  border-radius: 2px 2px 2px 2px;
  margin-left: 15px;
  margin-right: 15px;
}

.left-menu {
  width: 230px;
  height: calc(100vh - 65px - 200px);
  background: linear-gradient(to bottom, #02325f, #052b4b);
  /* background: linear-gradient(to bottom, #013655, #003254); */
  /* background: #052B4B; */
  border-radius: 2px 2px 2px 2px;
  margin: 100px 0px 0 0px;
  padding: 10px;
  box-sizing: border-box;
}

.menu-item-box {
  width: 100%;
  height: 45px;
  cursor: pointer;
}

.menu-item-l-box {
  width: 45px;
  height: 45px;

}

.menu-item-r-box {
  width: 166px;
  height: 45px;

}

.bgl1 {
  background-image: url('../assets/menu/wxuan.png');
  background-size: 100% 100%;
}

.bgl2 {
  background-image: url('../assets/menu/xuan.png');
  background-size: 100% 100%;
}

.bgr1 {
  background-image: url('../assets/menu/menu_choose.png');
  background-size: 100% 100%;
}

.bgr2 {
  background-image: url('../assets/menu/wmenu_choose.png');
  background-size: 100% 100%;
}


.right-box {
  width: 852px;
  height: calc(100vh - 65px - 100px);
  /* border: 1px solid; */
  margin-right: 20px;
}

.c-pointer{
  cursor: pointer;
}
</style>