import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style.css'
import ElementUI from 'element-ui';
//样式文件需要单独引入
import 'element-ui/lib/theme-chalk/index.css';
//引入 echarts
import echarts from 'echarts'
// 注册插件
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
// 设置全局背景色
Vue.prototype.$globalBackgroundColor = '#000';

//引入

import HeardList from '@/components/HeardList'
import Title from '@/components/Title'

// 注册为全局组件

Vue.component('HeardList', HeardList)
Vue.component('Title', Title)
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
