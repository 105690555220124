<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import autofit from "autofit.js";
export default {
  name: "App",
  // mounted() {
  //   autofit.init({
  //     dh: 1080,
  //     dw: 1920,
  //     el: "#app",
  //     resize: true
  //   }, false)
  // }
}
</script>
<style>
/* 隐藏垂直滚动条 */
body {
  overflow-y: hidden;
}
 
/* 隐藏水平滚动条 */
body {
  overflow-x: hidden;
}
 
/* 同时隐藏垂直和水平滚动条 */
body {
  overflow: hidden;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  min-width: 1280px;
}

#app {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 1280px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
