<template>
    <div class="heard-list d-flex  d-al-c d-ju-sa">
      <div class="d-flex flex-d-c d-al-c" v-for="(item,index) in heardList" :key="index">
        <div class="d-flex d-al-c">
            <img width="28px"  :src="item.img" alt="" srcset="">
            <div class="m-l-5 f-16">{{item.title}}</div>
        </div>
        <div class="m-t-10"> <span class="f-w-600 f-20 m-r-5 main-text-color">{{item.num}}</span> {{ item.unit }}</div>
      </div>
    </div>
  </template>
  <script>


  export default {
    data() {
      return {
        heardList: [
          { title: '当日测试总数', img: require(`@/assets/header_list/icon2.png`), num: 10,unit:'次' },
          { title: '动态测试总数', img: require(`@/assets/header_list/icon3.png`), num: 375,unit:'次' },
          { title: '静态测试总数', img: require(`@/assets/header_list/icon4.png`), num: 735,unit:'次' },
          { title: '被测对象总数', img: require(`@/assets/header_list/icon1.png`), num: 800,unit:'个' },
        ],
        menuIndex: 0
      }
    },
    mounted() {
  
    },
  
    methods: {
      
    }
  }
  </script>
  <style scoped>
  .heard-list{
    color: #fff;
  }
  </style>