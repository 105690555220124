import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
let testPath = 'test1'//这个值可以根据需求来改变
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'index' },
    component: () => import('@/views/HomeView.vue'),
    children:[
      {
        path:"/index",
        name:'index',
        component: () => import('@/views/Index.vue'),
      },
      {
        path:"/test",
        name:'test',
        redirect: { name: testPath },
        component: () => import('@/views/testViews/Test.vue'),
        children:[
          {
            path:"/test1",
            name:'test1',
            component: () => import('@/views/testViews/Test1.vue'),
          },
          {
            path:"/test2",
            name:'test2',
            component: () => import('@/views/testViews/Test2.vue'),
          },
          {
            path:"/test3",
            name:'test3',
            component: () => import('@/views/testViews/Test3.vue'),
          },
          {
            path:"/test4",
            name:'test4',
            component: () => import('@/views/testViews/Test4.vue'),
          },
          {
            path:"/test5",
            name:'test5',
            component: () => import('@/views/testViews/Test5.vue'),
          },
          {
            path:"/test6",
            name:'test6',
            component: () => import('@/views/testViews/Test6.vue'),
          },
          {
            path:"/test7",
            name:'test7',
            component: () => import('@/views/testViews/Test7.vue'),
          },
          {
            path:"/test8",
            name:'test8',
            component: () => import('@/views/testViews/Test8.vue'),
          },
        ]
      },
      {
        path:"/syhistory",
        name:'syhistory',
        component: () => import('@/views/SYHistory.vue'),
      },
      {
        path:"/pghistory",
        name:'pghistory',
        component: () => import('@/views/PGHistory.vue'),
      },
      {
        path:"/syssetting",
        name:'syssetting',
        component: () => import('@/views/SysSetting.vue'),
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Login.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
let originPush =  VueRouter.prototype.push;  //备份原push方法
 
VueRouter.prototype.push = function (location, resolve, reject){
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    }else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, ()=>{}, ()=>{}); 
    }
}

// router.beforeEach((to, from, next) => {
//   console.log(to.path,from.path);
//   if (to.path === from.path) {
//     // 当前路由和目标路由相同，直接取消导航
//     next(false);
//   } else {
//     // 路由不同，允许导航
//     next();
//   }
// })

// router.beforeEach((to, from, next) => {
//   // console.log(store.state.token)
//   // to: Route: 即将要进入的目标 路由对象
//   // from: Route: 当前导航正要离开的路由
//   // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
//   const route = ['index', 'list'];
//   let isLogin = false  // 是否登录
//   // 未登录状态；当路由到route指定页时，跳转至login
//   if (route.indexOf(to.name) >= 0) {
//     if (isLogin == null) {
//       router.push({ path: '/login', });
//     }
//   }
//   // 已登录状态；当路由到login时，跳转至home 
//   console.log(to.name)
//   localStorage.setItem('routerName', to.name)
//   if (to.name === 'login') {
//     if (isLogin != null) {
//       router.push({ path: '/', });;
//     }
//   }
//   next();
// });
export default router
