<template>
    <div>
        <div class="title-box d-flex d-al-c">
            <img width="30px" src="@/assets/title_icon.png" alt="" srcset="">
            <div class="f-16 m-l-10">{{ title }} </div>
        </div>
    </div>
  </template>
  <script>


  export default {
    props:{
        title:{
            type:String,
            default:"标题",
            require:true
        }
    },
    data() {
      return {
       
      }
    },
    mounted() {
  
    },
  
    methods: {
      
    }
  }
  </script>
  <style scoped>
  .title-box{
    color: #fff;
    width: 100%;
    height: 40px;
    border: 1px solid rgba(38, 100, 120, 0.5);
    border-left: none;
    border-right: none;
  }
  </style>